<template>
<el-dialog
  title="设备库"
  width="1000px"
  :close-on-click-modal="false"
  :visible="isShow"
  @close="isShow= false" >
    <query-form
      label-width="10px"
      @search="handleSearch"
      @reset="handleReset"
    >
      <el-form-item label="">
        <el-input v-model="form.name" placeholder="请输入设备名称" />
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.spec" placeholder="请输入设备型号" />
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.brandName" placeholder="请输入设备品牌" />
      </el-form-item>
    </query-form>
    <ContentBlock>
      <table-with-pagination
        :tableData="targetLists"
        :columnList="columns"
        ref="JZCheckbox"
        row-key="deviceId"
        :checkbox-config="checkboxConfig"
        @selection-change="handleCheckboxChange"
        :pagination.sync="pagination"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
        maxHeight="400px"
        empty-text="暂无数据"
      />
    </ContentBlock>
    <ContentBlock>
      <h4 :style="{ padding: '12px 0 0 0' }">已选择设备</h4>
      <table-with-pagination
        :tableData="selectedLists"
        :columnList="selectColumns"
        row-key="deviceId"
        empty-text="暂无数据"
        maxHeight="400px"
      />
    </ContentBlock>
    <span slot="footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleConfirm">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>

import { getBaseSparePartDeviceLists } from '@/api/systemSetting';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
export default {
  components: {
    TableWithPagination,
  },
  mixins: [TablePaginationMixin],
  props: {
    customerId: {
      type: String,
      default: '',
    },
    selectType: {
      type: String,
      default: 'checkbox',
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    assetSelectedRows: { // 资产数据已选数据
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columns () {
      return [
        {
          label: '全部',
          prop: 'name',
          width: 80,
          fixed: 'left',
          type: 'checkbox',
        },
        ...this.columnList,
      ];
    },
    selectColumns () {
      return this.columnList;
    },
  },
  data () {
    return {
      form: {
        name: undefined,
        spec: undefined,
        brandName: undefined,
      },
      columnList: [
        {
          label: '设备名称',
          prop: 'deviceName',
          minWidth: 130,
          tooltip: true,
        },
        {
          label: '设备型号',
          prop: 'deviceModel',
          minWidth: 130,
          tooltip: true,
        },
        {
          label: '设备品牌',
          prop: 'brandName',
          minWidth: 130,
          tooltip: true,
        },
        {
          label: '注册证号',
          prop: 'registerCode',
          minWidth: 180,
          tooltip: true,
        },
        {
          label: '设备厂家',
          prop: 'deviceManufacturer',
          minWidth: 180,
          tooltip: true,
        },
        {
          label: '设备分类',
          prop: 'categoryName',
          minWidth: 180,
          tooltip: true,
        },
      ],
      isShow: false,
      selectedLists: [],
      selectedIdLists: [],
      targetLists: [],
      checkboxConfig: {
        reserve: true,
        highlight: true,
      },
      idList: [],
    };
  },
  methods: {
    initPage () {
      this.isShow = true;
      if (this.idList && this.idList.length) {
        this.getSelectedLists();
      } else {
        this.selectedLists = [];
        this.handleSearch();
      }
      this.$nextTick(()=>{
        this.$refs.JZCheckbox.clearCheckboxRow();
        this.$refs.JZCheckbox.setCheckboxRow(this.selectedLists, true);
      });
    },
    handleReset () {
      this.form = {
        name: undefined,
        spec: undefined,
        brandName: undefined,
      };
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    async getTargetLists () {
      const { body } = await getBaseSparePartDeviceLists({
        ...this.form,
        status: 'PASS',
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      });
      this.targetLists = (body.list || []).map(i=>({
        ...i,
        id: '',
        deviceName: i.name,
        deviceModel: i.spec,
        deviceId: i.id,
        deviceBrandName: i.brandName,
        deviceRegisterCode: i.registerCode,
        deviceManufacturer: i.enterpriseName,
        deviceCategoryName: i.industryCategoryName,
      }));
      this.pagination.total = body.total;
    },
    getSelectedLists () {
      getBaseSparePartDeviceLists({
        ...this.form,
        status: 'PASS',
        idList: this.idList,
        pageFlag: false,
      }).then(({body}) => {
        this.selectedLists = (body.list || []).map(i=>({
          ...i,
          id: '',
          deviceName: i.name,
          deviceModel: i.spec,
          deviceId: i.id,
          deviceBrandName: i.brandName,
          deviceRegisterCode: i.registerCode,
          deviceManufacturer: i.enterpriseName,
          deviceCategoryName: i.industryCategoryName,
        }));
        this.handleSearch();
      });
    },
    handleClose () {
      this.isShow = false;
    },
    handleConfirm () {
      this.isShow = false;
      this.$emit('handleUpdateDevice', this.selectedLists);
    },
    handleCheckboxChange (list) {
      this.selectedLists = list;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ h4{
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  font-weight: 600;
}
</style>

