import server from '../request';

export default {
  getCustomerList (param, config) {
    param.orderByProperty = param.orderByProperty || 'lastUpdateTime';
    param.orderByType = param.orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    delete param.orderByType;
    delete param.orderByProperty;
    return server.post('/xiaozi-xmb/wireless/saas/customer/page', param, config);
  },

  addCustomer (param) {
    return server.post('/xiaozi-xmb/wireless/saas/customer/add', param);
  },

  updateCustomer (param) {
    return server.post('/xiaozi-xmb/wireless/saas/customer/update', param);
  },

  xiaoziAddCustomer (param) {
    return server.post('/xiaozi-xmb/wireless/saas/customer/xzErp/customer/addOrUpdate', param);
  },

  deleteCustomer (id) {
    let formData = new FormData();
    formData.append('id', id);
    return server.post('/xiaozi-xmb/wireless/saas/customer/delete', formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  getCustomerDetail (id) {
    let formData = new FormData();
    formData.append('id', id);
    return server.post('/xiaozi-xmb/wireless/saas/customer/detail', formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  getDetail (id) {
    return server.post('/xiaozi-saas/wireless/tenant/queryById', {id});
  },

  add ({applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    return server.post('/xiaozi-saas/wireless/tenant/add', {applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
  },

  active ({id, trialPeriod}) {
    return server.post('/xiaozi-saas/wireless/tenant/active', {id, trialPeriod});
  },

  close ({id, closeReason}) {
    return server.post('/xiaozi-saas/wireless/tenant/close', {id, closeReason});
  },

  delete (id) {
    return server.post('/xiaozi-saas/wireless/tenant/delById', {id});
  },

  resetPwd ({id, newPwd, oldPwd }) {
    return server.post('xiaozi-saas/wireless/tenant/reset', {id, newPwd, oldPwd});
  },

  approve ({id, openType, adminAccount, adminPassword, trialPeriod, trialStatus, applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    if (trialStatus === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialPass', {id, openType, adminAccount, adminPassword, trialPeriod, applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activePass', {id, openType, adminAccount, adminPassword, trialPeriod});
    }
  },

  reject ({id, rejectReason, status}) {
    if (status === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialReject', {id, rejectReason});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activeReject', {id, rejectReason});
    }
  },

  getHosipital ({name, enable, pageNum, pageSize}) {
    return server.post('/xiaozi-xmb/wireless/saas/customer/hospital/page', {name, enable, pageNum, pageSize});
  },
};
